import instance from "./services";
import { decrypt } from "../auth-guard";
/**
 * Realiza una solicitud GET a la ruta especificada, pasa la identificación como parámetro y devuelve los datos de respuesta
 * @param ruta {String} - La ruta a la que quieres acceder.
 * @param id {number} - El id del artículo que desea obtener.
 * @returns {(Promise  | JSON)} Los datos de respuesta por id.
 */

const obtenerPorId = async (RUTA, ID) => {
  try {
    const respuesta = await instance.get(`${RUTA}/${ID}`, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
      },
    });
    return respuesta.data;
  } catch (error) {
    return error.statusText;
  }
};
/**
 * Hace una solicitud GET a la ruta especificada y devuelve los datos de la respuesta
 * @param ruta {String} - La ruta a la API.
 * @returns {(Promise  | JSON)} Los datos de la respuesta.
 */
const obtenerTodo = async (RUTA) => {
  try {
    const respuesta = await instance.get(`${RUTA}`, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
        "Store-key": localStorage.getItem("store_key"),
      },
    });
    return respuesta.data;
  } catch (error) {
    return error.statusText;
  }
};
/**
 * Envía una solicitud POST para crear a la ruta especificada con los datos especificados y devuelve los datos de respuesta.
 * @param data - TLos datos a enviar al servidor.
 * @param ruta {String} - La ruta de la api.
 * @param content {String} - El tipo de formato que se le envia ala api.
 * @returns {(Promise | Array )} Los datos de respuesta del servidor.
 */
const crear = async (RUTA, DATA, CONTENT) => {
  try {
    DATA.id_user_log = decrypt(localStorage.getItem("id")).id;
    if (CONTENT == "json") {
      const respuesta = await instance.post(`${RUTA}`, DATA, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? decrypt(localStorage.getItem("token")).token
              : null
          }`,
          "Content-Type": "application/json",
          "Store-key": localStorage.getItem("store_key"),
        },
      });
      return respuesta.status;
    }
    const respuesta = await instance.post(`${RUTA}`, DATA, {
      headers: {
        "Content-Type": CONTENT,
        "Store-key": localStorage.getItem("store_key"),
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
      },
    });
    return respuesta.status;
  } catch (error) {
    return error.response.statusText;
  }
};
/**
 * Envía una solicitud PUT al servidor con los datos y la ruta pasada como parámetros.
 * @param ruta {String} - La ruta de la api.
 * @param data - Los datos a enviar al servidor..
 * @returns  {(Promise | Array )} Los datos de respuesta del servidor.
 */
const actualizar = async (RUTA, DATA) => {
  try {
    DATA.id_user_log = decrypt(localStorage.getItem("id")).id;
    const respuesta = await instance.put(`${RUTA}?_method=PUT`, DATA, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
      },
    });
    return respuesta.status;
  } catch (error) {
    return error.statusText;
  }
};

const filtrar = async (RUTA, DATA) => {
  try {
    DATA.id_user_log = decrypt(localStorage.getItem("id")).id;
    const respuesta = await instance.post(`${RUTA}`, DATA, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
        "Content-Type": "application/json",
      },
    });
    return respuesta.data;
  } catch (error) {
    return error.statusText;
  }
};

const subirArchivo = async (RUTA, ARCHIVO, DATOS_ADICIONALES = {}) => {
  try {
    const formData = new FormData();
    formData.append("archivo", ARCHIVO);

    Object.keys(DATOS_ADICIONALES).forEach((key) => {
      formData.append(key, DATOS_ADICIONALES[key]);
    });

    const respuesta = await instance.post(`${RUTA}`, formData, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? decrypt(localStorage.getItem("token")).token
            : null
        }`,
        "Content-Type": "multipart/form-data",
        "Store-key": localStorage.getItem("store_key"),
      },
    });

    return respuesta.data;
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw error.response?.data || "Error al procesar la solicitud.";
  }
};

export { obtenerPorId, obtenerTodo, filtrar, crear, actualizar, subirArchivo };
