<template>
  <div class="grid justify-center">
    <div class="col-12">
      <div class="card shadow-lg">
        <Toast
          :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
        />
        <!-- Encabezado -->
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4 text-lg font-bold">Gestión de Inventario</h5>
          </template>
        </Toolbar>

        <!-- Descripción -->
        <div class="mb-6">
          <p class="text-gray-700 text-base">
            Suba un archivo para integrar fotos, productos y stock al sistema.
          </p>
          <div class="flex items-center align-items-center mt-4">
            <Button
              label="Ver Datos de Conexión FTP"
              icon="pi pi-server"
              @click="showDialog = true"
              class="p-button-text pl-0"
            />
            <p class="font-semibold text-gray-600 ml-2">
              Directorio FTP:
              <span class="font-mono text-gray-800">/Producto</span>
            </p>
          </div>
        </div>

        <!-- Zona de carga -->
        <div class="flex flex-col items-center gap-4">
          <div class="flex items-center gap-3">
            <FileUpload
              ref="fileupload"
              mode="basic"
              name="demo[]"
              accept=".xlsx, .xls"
              chooseLabel="Seleccionar archivo"
              :maxFileSize="5242880"
              class="w-full max-w-sm b"
            />
            <Button
              label="Subir"
              @click="onUpload"
              severity="secondary"
              :disabled="loading"
            />
            <ProgressSpinner
              v-if="loading"
              style="width: 40px; height: 40px"
              strokeWidth="6"
              fill="var(--surface-ground)"
            />
          </div>
          <div class="flex align-items-center">
            <small class="text-gray-500">
              Solo se aceptan archivos con formato <b>.xls</b> o
              <b>.xlsx</b> (máximo 5MB).
            </small>
          </div>
        </div>

        <!-- Tabla de archivos subidos -->
        <div class="mt-6">
          <h6 class="text-lg font-medium text-gray-700 mb-2">
            Archivos subidos
          </h6>
          <br />
          <div v-if="archivos.length">
            <DataTable
              :value="archivos"
              class="p-datatable-sm"
              dataKey="id"
              :paginator="true"
              :rows="10"
            >
              <Column field="name" header="Nombre del Archivo"></Column>
              <Column field="size" header="Tamaño del Archivo"></Column>
            </DataTable>
          </div>
          <p v-else class="text-gray-500 text-center">
            No hay archivos subidos.
          </p>
        </div>

        <!-- Dialog de Conexión FTP -->
        <Dialog
          header="Datos de Conexión FTP"
          v-model:visible="showDialog"
          :style="{ width: '450px' }"
          :modal="true"
          class="p-fluid"
        >
          <div v-if="ftpData">
            <p><b>Host:</b> {{ ftpData.host }}</p>
            <p><b>Puerto:</b> {{ ftpData.puerto }}</p>
            <p><b>Usuario:</b> {{ ftpData.usuario }}</p>
            <p><b>Contraseña:</b> * * * * *</p>
            <small class="text-gray-500">
              Para obtener la contraseña, comuníquese con el Administrador.
            </small>
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { decrypt } from "@/auth-guard";
import { subirArchivo, obtenerTodo } from "@/api/clienteHttpService";
import { ProgressSpinner } from "primevue/progressspinner";

export default {
  components: {
    ProgressSpinner,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      messages: {
        severity: "",
        content: "",
      },
      archivos: [],
      loading: false,
      id_tienda: null,
      ftpData: {}, // Para almacenar los datos de conexión FTP
      totalRecords: 0, // Total de registros de archivos
      first: 0, // Página actual
      showDialog: false, // Controlar la visibilidad del Dialog
    };
  },
  created() {
    this.tienda = decrypt(localStorage.getItem("id"));
    this.id_tienda = this.tienda.id_tienda;
    this.obtenerArchivos();
  },
  methods: {
    async onUpload() {
      const fileInput = this.$refs.fileupload.files;
      if (!fileInput || !fileInput.length) {
        this.showMessage("warn", "Por favor selecciona un archivo.");
        return;
      }
      const archivo = fileInput[0];
      const datosAdicionales = { id_tienda: this.id_tienda };
      this.loading = true;
      try {
        await subirArchivo(
          "/productos/integraciones/subirArchivoFTP",
          archivo,
          datosAdicionales
        );
        this.showMessage("success", "Archivo subido con éxito.");
        this.$refs.fileupload.clear();
        this.obtenerArchivos();
      } catch (error) {
        this.showMessage("error", "Error al subir el archivo.");
      } finally {
        this.loading = false;
      }
    },
    async obtenerArchivos() {
      try {
        const respuesta = await obtenerTodo(
          `/productos/integraciones/listarArchivosFTP?id_tienda=${this.id_tienda}`
        );
        this.archivos = respuesta.files || [];
        this.ftpData = respuesta.ftp || {}; // Obtener los datos de FTP
        this.totalRecords = this.archivos.length; // Asignar el total de registros
      } catch (error) {
        console.error(error);
      }
    },
    showMessage(severity, content) {
      this.$toast.add({
        severity,
        summary: severity === "success" ? "Éxito" : "Error",
        detail: content,
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
/* Mejoras visuales opcionales */
</style>
